export const Theme = {
  Yellow: "#fff200",
  Gray: "#e7e7e7",
  DarkGray: "#7c7c7c",
  SoftGray: "#f3f3f3",
  DarkYellow: "#bfb500",
  White: "#ffffff",
  Black: "#1f191a",
  Red: "#991212",
  Blue: "#2d82be",
  FontAleo: "'Aleo', serif",
  ContainerSmall: 500,
  ContainerMedium: 630,
  ContainerCommon: 840,
  ContainerLarge: 950,
  ContainerExtraLarge: 1300,
  SidebarWidth: 65,
  SidebarWidth2x: 79,
  //media query
  largeBreakPoint:
    "@media only screen and (min-width: 1400px), only screen and (min-width: 500px) and (min-resolution: 2dppx)",
  ipadBreakPoint: "@media only screen and (max-width: 1024px)",
  mediumBreakPoint: "@media only screen and (max-width: 970px)",
  smallBreakPoint: "@media only screen and (max-width: 750px)",
  xlBreakPoint: "@media only screen and (max-width: 450px)",
  smBP: "@media only screen and (max-width: 320px)",
  mdBP: "@media only screen and (min-width: 321px) and (max-width: 768px)",
  lgBP: "@media only screen and (min-width: 769px) and (max-width: 1024px)",
  xlBP: "@media only screen and (min-width: 1025px)",

}
